/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React from "react";
import {Select, SelectItem, SelectItemGroup} from "@carbon/react";

const InDepthSelectionMenu = ({onMenuSelect=f=>f, ...props}) =>
    <Select
        {...props}
        id="drug-points-indepth-menu"
        labelText=""
        onChange={(event) => onMenuSelect(event)}
        className="inDepthSelect"
    >
        <SelectItemGroup label="Dosing/Administration" >
            <SelectItem value="adultDosingSection" text="Adult Dosing" />
            <SelectItem value="pediatricDosageSection" text="Pediatric Dosing" />
            <SelectItem value="fdaUsesSection" text="FDA Uses" />
            {/*<SelectItem value="raUsesSection" text="Labeled Uses" />*/}
            <SelectItem value="nonFdaUsesSection" text="Non-FDA Uses" />
            <SelectItem value="doseAdjustmentsSection" text="Dose Adjustments" />
            <SelectItem value="administrationSection" text="Administration" />
            <SelectItem value="comparativeEfficacySection" text="Comparative Efficacy" />
            <SelectItem value="placeInTherapySection" text="Place In Therapy" />
        </SelectItemGroup>
        <SelectItemGroup label="Medication Safety" >
            <SelectItem value="contraindications" text="Contraindications" />
            <SelectItem value="precautions" text="Precautions" />
            <SelectItem value="adverseReactionsSection" text="Adverse Effects" />
            <SelectItem value="blackboxwarning" text="Boxed Warning" />
            <SelectItem value="rems" text="REMS" />
            <SelectItem value="druginteractions" text="Drug Interactions (single)" />
            <SelectItem value="IVcompatibility" text="IV Compatibility (single)" />
            <SelectItem value="pregnancyandlactation" text="Pregnancy & Lactation" />
            <SelectItem value="monitoring" text="Monitoring" />
            <SelectItem value="donotconfuse" text="Do Not Confuse" />
        </SelectItemGroup>
        <SelectItemGroup label="Mechanism of Action" >
            <SelectItem value="mechanismofaction" text="Mechanism of Action" />
        </SelectItemGroup>
        <SelectItemGroup label="Pharmacokinetics" >
            <SelectItem value="pharmacokinetics" text="Pharmacokinetics" />
        </SelectItemGroup>
        <SelectItemGroup label="Patient Education" >
            <SelectItem value="medicationconsulting" text="Medication Counseling" />
            <SelectItem value="patienthandouts" text="Patient Handouts" />
        </SelectItemGroup>
        <SelectItemGroup label="Toxicology" >
            <SelectItem value="clinicaleffects" text="Clinical Effects" />
            <SelectItem value="rangeoftoxicity" text="Range of Toxicity" />
            <SelectItem value="treatment" text="Treatment" />
        </SelectItemGroup>
        <SelectItemGroup label="About" >
            <SelectItem value="howsupplied" text="How Supplied" />
            <SelectItem value="drugproperties" text="Drug Properties" />
            <SelectItem value="storageandstability" text="Storage & Stability" />
            <SelectItem value="tradenames" text="Trade Names" />
            <SelectItem value="regulatorystatus" text="Regulatory Status" />
            <SelectItem value="references" text="References" />
        </SelectItemGroup>
    </Select>

export default InDepthSelectionMenu