/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {get, getArray} from "../../../utils";
import {DRUGS_AND_ALLERGIES_SECTIONS, SEVERITIES_SECTIONS} from "../utils";

/**
 * This method is way too big -- cyclomatic complexity is off the charts:
 * https://en.wikipedia.org/wiki/Cyclomatic_complexity
 *
 * Needs to be refactored into bite sized, testable, methods
 *
 * @param drugFilter
 * @param filteredWarnings
 * @param drug_interaction_objects
 * @returns {*[]}
 */
export function filterWarningListByDrugs(drugFilter, filteredWarnings,drug_interaction_objects) {
    let warningsToKeep = [];
    console.debug(filteredWarnings)

    for (let i = 0; i < filteredWarnings.length; i++) {
        //If the warning is a drug-drug warning, do one thing....
        if (['DRUG', 'INGREDIENT_DUPLICATION'].includes(filteredWarnings[i].type)) {
            let primaryWarningItems = getArray(filteredWarnings[i], 'primary_warning_items');
            let secondaryWarningItems = getArray(filteredWarnings[i], 'secondary_warning_items');

            //Make sure that both the primary and secondary items are selected drugs.
            let primaryWasFound = false;
            let secondaryWasFound = false;
            for (let j = 0; j < drugFilter.length; j++) {
                for (let k = 0; !primaryWasFound && k < primaryWarningItems.length; k++) {
                    let warningItemId = primaryWarningItems[k].id;
                    let warningItemType = primaryWarningItems[k].idType;
                    const interaction_objects_drugs = drug_interaction_objects.drugs;
                    let interaction_objects_drugs_name;
                    interaction_objects_drugs.forEach(interaction_objects_drug => {
                        if (interaction_objects_drug.id === drugFilter[j]) {
                            interaction_objects_drugs_name = interaction_objects_drug.name.toUpperCase();
                        }
                    });
                    if (warningItemType === 'GCR'
                        && [drugFilter[j], interaction_objects_drugs_name].includes(warningItemId))
                    {
                        primaryWasFound = true;
                        break;
                    }
                }

                for (let k = 0; !secondaryWasFound && k < secondaryWarningItems.length; k++) {
                    let warningItemId = secondaryWarningItems[k].id;
                    let warningItemType = secondaryWarningItems[k].idType;
                    if (warningItemType === 'GCR' && warningItemId === drugFilter[j]) {
                        secondaryWasFound = true;
                        break;
                    }
                }
            }
            if (primaryWasFound && secondaryWasFound && !warningAlreadyInList(warningsToKeep, filteredWarnings[i])) {
                warningsToKeep.push(filteredWarnings[i]);
            }
        } //endif ['DRUG', 'INGREDIENT_DUPLICATION'].includes(filteredWarnings[i].type)

        //And if it's a drug-something-else warning, do the other....
        else {
            //For each warning that isn't drug-drug, check to see if it or its secondary are drugs we care about.
            for (let j = 0; j < drugFilter.length; j++) {
                let resultWasFound = false;
                //Gotta check both primary and secondary warning items.
                let primaryWarningItems = getArray(filteredWarnings[i], 'primary_warning_items');
                for (let k = 0; k < primaryWarningItems.length; k++) {
                    let warningItemId = primaryWarningItems[k].id;
                    let warningItemType = primaryWarningItems[k].idType;
                    const interaction_objects_drugs = drug_interaction_objects.drugs;
                    let interaction_objects_drugs_name;
                    interaction_objects_drugs.forEach(interaction_objects_drug => {
                        if (interaction_objects_drug.id === drugFilter[j]) {
                            interaction_objects_drugs_name = interaction_objects_drug.name.toUpperCase();
                        }
                    });
                    if (warningItemType === 'GCR'
                        && [drugFilter[j], interaction_objects_drugs_name].includes(warningItemId)
                        && !warningAlreadyInList(warningsToKeep, filteredWarnings[i]))
                    {
                        warningsToKeep.push(filteredWarnings[i]);
                        resultWasFound = true;
                        break;
                    }
                }

                if (resultWasFound) {
                    //Move on, don't check secondary items if we've already confirmed a match.
                    //Save processing power *and* avoid dupes.
                    break;//Once we found results go to main loop ..., don't continue in current loop.
                }

                //Now check secondary warning items if they exist...
                let secondaryWarningItems = getArray(filteredWarnings[i], 'secondary_warning_items');
                for (let k = 0; k < secondaryWarningItems.length; k++) {
                    let warningItemId = secondaryWarningItems[k].id;
                    let warningItemType = secondaryWarningItems[k].idType;

                    const interaction_objects_drugs = drug_interaction_objects.drugs;
                    let interaction_objects_drugs_name;
                    interaction_objects_drugs.forEach(interaction_objects_drug => {
                        if (interaction_objects_drug.id === drugFilter[j]) {
                            interaction_objects_drugs_name = interaction_objects_drug.name.toUpperCase();
                        }
                    });
                    if (warningItemType === 'GCR'
                        && [drugFilter[j], interaction_objects_drugs_name].includes(warningItemId)
                        && !warningAlreadyInList(warningsToKeep, filteredWarnings[i]))
                    {
                        warningsToKeep.push(filteredWarnings[i]);
                        break;
                    } //endif warningItemType==='GCR'
                } //endfor k
            } //endfor j
        } //endelse ['DRUG', 'INGREDIENT_DUPLICATION'].includes(filteredWarnings[i].type)
    } //endfor i

    return warningsToKeep;
}


export function filterWarningListByAllergies(allergyFilter, filteredWarnings) {
    let warningsToKeep = [];

    //For each warning...
    for (let i = 0; i < filteredWarnings.length; i++) {
        let warning = filteredWarnings[i];

        //Look at the type first to see if we even have to filter it...
        if (warning.type !== 'ALLERGY') {
            warningsToKeep.push(warning); //Keep it, we don't filter non-allergy things in the allergen filter.
            continue;
        }

        //Okay, it is an allergy warning. In that case, make sure it
        //actually contains one of the allergens we're keeping.
        allergyFilter.forEach(filteredAllergy => {
            let secondaryWarningItems = getArray(filteredWarnings[i], 'secondary_warning_items');

            //Search secondary for the allergy.
            secondaryWarningItems.forEach(secondaryWarningItem => {
                if (secondaryWarningItem.idType === 'MDX_ALG' && secondaryWarningItem.id === filteredAllergy
                    && !warningAlreadyInList(warningsToKeep, warning))
                {
                    warningsToKeep.push(warning);
                }
            });
        });
    }

    return warningsToKeep;
}


export function filterWarningListBySeverity(severityFilter, filteredWarnings) {
    let warningsToKeep = [];
    filteredWarnings.forEach(warning => {
        let warningSeverity = warning.severity.toUpperCase();
        //Now compare to all of our severity filters to see if we keep it.
        severityFilter.map(filteredSeverity => filteredSeverity.toUpperCase())
            .forEach(filteredSeverity => {
                if (filteredSeverity === warningSeverity && !warningAlreadyInList(warningsToKeep, warning)) {
                    warningsToKeep.push(warning);
                }
            });
    });
    return warningsToKeep;
}


export function filterWarningListByDocumentation(documentationFilter, filteredWarnings) {
    let warningsToKeep = [];
    filteredWarnings.forEach(warning => {
        let warningDocumentation = get(warning, 'document_rating', "").toUpperCase();

        //Now compare to all of our documentation filters to see if we keep it.
        documentationFilter.map(filteredDocumentation => filteredDocumentation.toUpperCase())
            .forEach(filteredDocumentation => {
                if ((filteredDocumentation === warningDocumentation ||
                    (filteredDocumentation === 'UNAVAILABLE' && warningDocumentation === 'NOT SPECIFIED'))
                    && !warningAlreadyInList(warningsToKeep, warning))
                {
                    warningsToKeep.push(warning);
                }
            });
    });
    return warningsToKeep;
}


export function filterWarningListByType(typeFilter, filteredWarnings) {
    let warningsToKeep = [];
    filteredWarnings.forEach(warning => {
        let warningType = get(warning, 'type', '').toUpperCase();
        //Now compare to all of our documentation filters to see if we keep it.
        typeFilter.map(filteredType => filteredType.toUpperCase())
            .forEach(filteredType => {
                if (filteredType === 'INGREDIENT DUPLICATION') {
                    filteredType = filteredType.replace(' ', '_');
                }
                if (filteredType === warningType && !warningAlreadyInList(warningsToKeep, warning)) {
                    warningsToKeep.push(warning);
                }
            });
    });

    return warningsToKeep;
}



function warningAlreadyInList(warningList, warningToCheck) {
    for(let i = 0; i < warningList.length; i++){
        let thisWarning = warningList[i];
        if(thisWarning.id === warningToCheck.id) {
            // let result=true;
            // let isContinueForCheck=false;
            let this_secondary = thisWarning.secondary_warning_items;
            let warningToCheck_secondary = warningToCheck.secondary_warning_items;
            if(this_secondary!==undefined&&warningToCheck_secondary!==undefined) {
                for (let q = 0; q < this_secondary.length; q++) {
                    if (this_secondary[q].id !== warningToCheck_secondary[q].id) {
                        return false;
                    }
                }
            }
            let this_primary = thisWarning.primary_warning_items;
            let warningToCheck_primary = warningToCheck.primary_warning_items;
            for(let j=0; j<this_primary.length;j++){
                if(this_primary[j].id !== warningToCheck_primary[j].id) {
                    return false;
                }
            }

            return true;
        }
    }

    return false;
}


export function isTempFilterDifferentFromRealFilter(tempFilter, realFilter, sectionName) {
    if (DRUGS_AND_ALLERGIES_SECTIONS.includes(sectionName)) {
        return isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, 'drugs')
            || isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, 'allergies');
    }
    if (SEVERITIES_SECTIONS.includes(sectionName)) {
        return isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, 'severity')
            || isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, 'documentation')
            || isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, 'type');
    }
    return false;
}

export function shouldDisableApply(tempFilter, realFilter, invokedSectionName, drugs) {
    return (!isTempFilterDifferentFromRealFilter(tempFilter, realFilter, invokedSectionName)
        || (drugs.length > 0 && getArray(tempFilter, 'drugs').length === 0)
        || getArray(tempFilter, 'documentation').length === 0
        || getArray(tempFilter, 'severity').length === 0
        || getArray(tempFilter, 'type').length === 0);
}

export function isTempFilterTypeDifferentFromRealFilterType(tempFilter, realFilter, filterType) {
    //Check documentation
    let tempFilterType = getArray(tempFilter, filterType);
    let realFilterType = getArray(realFilter, filterType);

    if (tempFilterType.length !== realFilterType.length) {
        return true;
    }

    for (let i = 0; i < realFilterType.length; i++) {
        if (!tempFilterType.includes(realFilterType[i]))
            return true;
    }

    return false;
}