/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

const TEMPLATE_TYPE_WAITING = "waiting"                                     //  0
const TEMPLATE_TYPE_IN_DEPTH = "in_depth"//  1
const TEMPLATE_TYPE_REFERENCES = "references_format"                        // 10
const TEMPLATE_TYPE_IV_COMPATIBILITY = "iv_compatibility";
const TEMPLATE_TYPE_LINK_TO_QUICK_ANSWERS = "link_to_quick_answers"

const  in_depth_reference_data = {

  "all_indepth_topics_invalid" : ["ivCompatibilitySection", "snippet_regulatoryStatusSection", "snippet_clinicalEffectsSection",
            "snippet_clinicalTeachingSection", "snippet_howSuppliedSection", "snippet_remsSection", "snippet_tradenamesSection",
            "snippet_treatmentSection", "snippet_rangeOfToxicitySection", "snippet_doNotConfuseSection"],

  "all_indepth_topics_valid" : ["snippet_adultDosageSection", "referencesSection","staticInfo", "adverseReactionsSection",
  "snippet_raUsesSection", "snippet_nonFdaUsesSection", "snippet_pediatricDosageSection", "pharmacokineticsSection",
  "snippet_storageAndStabilitySection",
  "snippet_comparativeEfficacyAndEvaluationWithOtherTherapiesSection","snippet_drugInteractionsSection", "snippet_mechanismOfActionPharmacologySection",
  "snippet_teratogenicityEffectsInPregnancyBreastfeedingSection", "snippet_blackBoxWarningSection",
  "snippet_monitoringParametersSection", "snippet_patientInstructionsSection", "snippet_dosageFormsSection",
  "contraindicationsSection", "snippet_placeInTherapySection",
   "precautionsSection"],

   "all_indepth_topics": {
                           "adultDosageSection":"snippet_adultDosageSection",
                           "pediatricDosageSection":"snippet_pediatricDosageSection",
   						              "raUsesSection":"snippet_raUsesSection",
                           "nonFdaUsesSection":"snippet_nonFdaUsesSection",
                           "doseAdjustmentsSection":"snippet_doseAdjustmentsSection",
                           "comparativeEfficacyAndEvaluationWithOtherTherapiesSection":"snippet_comparativeEfficacyAndEvaluationWithOtherTherapiesSection",
                           "placeInTherapySection":"snippet_placeInTherapySection",


                           "contraindicationsSection":"contraindicationsSection",
                           "precautionsSection":"precautionsSection",
                           "adverseReactionsSection":"adverseReactionsSection",
                           "blackBoxWarningSection":"snippet_blackBoxWarningSection",
                           "remsSection":"snippet_remsSection",
                           "drugInteractionsSection":"snippet_drugInteractionsSection",
                           "ivCompatibilitySection":"ivCompatibilitySection",
                           "teratogenicityEffectsInPregnancyBreastfeedingSection":"snippet_teratogenicityEffectsInPregnancyBreastfeedingSection",
                           "monitoringParametersSection":"snippet_monitoringParametersSection",
                           "doNotConfuseSection":"snippet_doNotConfuseSection",


                           "mechanismOfActionPharmacologySection":"snippet_mechanismOfActionPharmacologySection",


                           "pharmacokineticsSection":"pharmacokineticsSection",


                           "clinicalTeachingSection":"snippet_clinicalTeachingSection",
                           "patientInstructionsSection":"snippet_patientInstructionsSection",


                           "clinicaleffectsSection":"snippet_clinicalEffectsSection",
                           "rangeOfToxicitySection":"snippet_rangeOfToxicitySection",
                           "treatmentSection":"snippet_treatmentSection",

                           "howSuppliedSection":"snippet_howSuppliedSection",
                           "dosageFormsSection":"snippet_dosageFormsSection",
                           "storageAndStabilitySection":"snippet_storageAndStabilitySection",
                           "tradenamesSection":"snippet_tradenamesSection",
                           "regulatorystatus":"snippet_regulatoryStatusSection",
                           "referencesSection":"referencesSection",

                           "staticInfo":"staticInfo"
         },

    "content_set_id": "31",
    "in-depth":"31",
    "GET_DOSAGE_FROM_DRUG_FINDING_CONDITION_INDICATION:adult":"adultDosingSection",
    "adultDosingSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_adultDosageSection",
        "group_title": "Dosing/Administration",
        "section_title": "Adult Dosing",
        "topic_ids": [
            "snippet_adultDosageSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "GET_DOSAGE_FROM_DRUG_FINDING_CONDITION_INDICATION:pediatric":"pediatricDosageSection",
    "pediatricDosageSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_pediatricDosageSection",
        "group_title": "Dosing/Administration",
        "section_title": "Pediatric Dosing",
        "topic_ids": [
            "snippet_pediatricDosageSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "fdaUsesSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_raUsesSection",
        "group_title": "Dosing/Administration",
        "section_title": "FDA Uses",
        "topic_ids": [
            "snippet_raUsesSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "raUsesSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_raUsesSection",
        "group_title": "Dosing/Administration",
        "section_title":"Labeled Uses",
        "topic_ids": [
            "snippet_raUsesSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "nonFdaUsesSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_nonFdaUsesSection",
        "group_title": "Dosing/Administration",
        "section_title":"Non-FDA Uses",
        "topic_ids": [
            "snippet_nonFdaUsesSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "doseAdjustmentsSection": {
        "template_type": "in_depth",
        "main_topic": ["snippet_adultDosageSection","snippet_pediatricDosageSection"],
        "group_title": "Dosing/Administration",
        "section_title": "Dose Adjustments",
        "topic_ids": [
            "snippet_adultDosageSection",
            "snippet_pediatricDosageSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "GET_DOSEADMINISTRATION_FROM_DRUG":"administrationSection",
    "administrationSection": {
        "template_type": "in_depth",
        "main_topic": "snippet_storageAndStabilitySection",
        "group_title": "Dosing/Administration",
        "section_title": "Administration",
        "topic_ids": [
            "snippet_storageAndStabilitySection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "comparativeEfficacySection": {
        "template_type": "in_depth",
        "group_title": "Dosing/Administration",
        "main_topic": "snippet_comparativeEfficacyAndEvaluationWithOtherTherapiesSection",
        "section_title": "Comparative Efficacy",
        "topic_ids": [
            "snippet_comparativeEfficacyAndEvaluationWithOtherTherapiesSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "placeInTherapySection": {
        "template_type": "in_depth",
        "group_title": "Dosing/Administration",
        "main_topic": "snippet_placeInTherapySection",
        "section_title": "Place In Therapy",
        "topic_ids": [
            "snippet_placeInTherapySection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "contraindications": {
        "template_type": "in_depth",
        "main_topic": "contraindicationsSection",
        "group_title":"Medication Safety",
        "section_title": "Contraindications",
        "topic_ids": [
            "contraindicationsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "precautions": {
        "template_type": "in_depth",
        "main_topic": "precautionsSection",
        "group_title":"Medication Safety",
        "section_title": "Precautions",
        "topic_ids": [
            "precautionsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "adverseReactionsSection": {
        "template_type": "in_depth",
        "group_title":"Medication Safety",
        "section_title": "Adverse Effects",
        "main_topic": "adverseReactionsSection",
        "topic_ids": [
            "adverseReactionsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "GET_BLACKBOXWARNING_FROM_DRUG":"blackboxwarning",
    "blackboxwarning": {
        "template_type": "in_depth",
        "main_topic": "snippet_blackBoxWarningSection",
        "group_title":"Medication Safety",
        "section_title": "Boxed Warning",
        "topic_ids": [
            "snippet_blackBoxWarningSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "GET_REMS_FROM_DRUG":"rems",
    "rems": {
        "template_type": "in_depth",
        "main_topic": "snippet_remsSection",
        "group_title":"Medication Safety",
        "section_title": "REMS",
        "topic_ids": [
            "snippet_remsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "druginteractions": {
        "template_type": "in_depth",
        "group_title":"Medication Safety",
        "section_title": "Drug Interactions (single)",
        "main_topic":"snippet_drugInteractionsSection",
        "topic_ids": [
            "snippet_drugInteractionsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "IVcompatibility": {
        "template_type": "iv_compatibility",
        "group_title":"Medication Safety",
        "section_title": "IV Compatibility (single)",
        "topic_ids": [
            "ivCompatibilitySection"
        ]
    },
    "GET_PREGNANCYLACTATION_FROM_DRUG":"pregnancyandlactation",
    "pregnancyandlactation": {
        "template_type": "in_depth",
        "group_title":"Medication Safety",
        "section_title": "Pregnancy & Lactation",
        "main_topic":"snippet_teratogenicityEffectsInPregnancyBreastfeedingSection",
        "topic_ids": [
            "snippet_teratogenicityEffectsInPregnancyBreastfeedingSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "monitoring": {
        "template_type": "in_depth",
        "main_topic": "snippet_monitoringParametersSection",
        "group_title":"Medication Safety",
        "section_title": "Monitoring",
        "topic_ids": [
            "snippet_monitoringParametersSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "donotconfuse": {
        "template_type": "in_depth",
        "main_topic": "snippet_doNotConfuseSection",
        "group_title":"Medication Safety",
        "section_title": "Do Not Confuse",
        "topic_ids": [
            "snippet_doNotConfuseSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "mechanismofaction": {
        "template_type": "in_depth",
        "main_topic": "snippet_mechanismOfActionPharmacologySection",
        "group_title": "Mechanism of Action",
        "section_title": "Mechanism of Action",
        "topic_ids": [
            "snippet_mechanismOfActionPharmacologySection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "GET_PHARMACOKINETICS_FROM_DRUG":"pharmacokinetics",
    "pharmacokinetics": {
        "template_type": "in_depth",
        "group_title":"Pharmacokinetics",
        "main_topic":"pharmacokineticsSection",
        "section_title":"Pharmacokinetics",
        "topic_ids": [
            "pharmacokineticsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "medicationconsulting": {
        "template_type": "in_depth",
        "main_topic": "snippet_clinicalTeachingSection",
        "group_title": "Patient Education",
        "section_title": "Medication Counseling",
        "topic_ids": [
            "snippet_clinicalTeachingSection", // ???????????????????
            "staticInfo",
            "referencesSection"
        ]
    },
    "patienthandouts": {
        "template_type": "in_depth",
        "group_title":"Patient Education",
        "section_title": "Patient Handouts",
        "main_topic":"snippet_patientInstructionsSection",
        "topic_ids": [
            "snippet_patientInstructionsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    // TODO: determine intent for "clinicaleffects"
    "clinicaleffects": {
        "template_type": "in_depth",
        "main_topic": "snippet_clinicalEffectsSection",
        "group_title": "Toxicology",
        "section_title": "Clinical Effects",
        "topic_ids": [
            "snippet_clinicalEffectsSection"
        ]
    },
    // TODO: determine intent for "rangeoftoxicity"
    "rangeoftoxicity": {
        "template_type": "in_depth",
        "main_topic": "snippet_rangeOfToxicitySection",
        "group_title": "Toxicology",
        "section_title": "Range of Toxicity",
        "topic_ids": [
            "snippet_rangeOfToxicitySection"
        ]
    },
    // TODO: determine intent for "treatment"
    "treatment": {
        "template_type": "in_depth",
        "main_topic": "snippet_treatmentSection",
        "group_title": "Toxicology",
        "section_title": "Treatment",
        "topic_ids": [
            "snippet_treatmentSection"
        ]
    },
    "GET_HOWSUPPLIED_FROM_DRUG":"howsupplied",
    "howsupplied": {
        "template_type": "in_depth",
        "main_topic": "snippet_howSuppliedSection",
        "group_title":"About",
        "section_title": "How Supplied",
        "topic_ids": [
            "snippet_howSuppliedSection"
        ]
    },
    "drugproperties": {
        "template_type": "in_depth",
        "group_title":"About",
        "section_title": "Drug Properties",
        "main_topic":"snippet_dosageFormsSection",
        "topic_ids": [
            "snippet_dosageFormsSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "storageandstability": {
        "template_type": "in_depth",
        "group_title":"About",
        "main_topic" :"snippet_storageAndStabilitySection",
        "section_title": "Storage & Stability",
        "topic_ids": [
            "snippet_storageAndStabilitySection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "tradenames": {
        "template_type": "in_depth",
        "main_topic": "snippet_tradenamesSection",
        "group_title":"About",
        "section_title": "Trade Names",
        "topic_ids": [
            "snippet_tradenamesSection"
        ]
    },
    "regulatorystatus": {
        "template_type": "in_depth",
        "main_topic": "snippet_regulatoryStatusSection",
        "group_title": "About",
        "section_title": "Regulatory Status",
        "topic_ids": [
            "snippet_regulatoryStatusSection",
            "staticInfo",
            "referencesSection"
        ]
    },
    "references": {
        "template_type": "references_format",
        "main_topic": "referencesSection",
        "group_title": "About",
        "section_title": "References",
        "topic_ids": [
            "referencesSection"
        ]
    },
    "topic_id_to_section": {
        "snippet_adultDosingSection": [
            "adultDosingSection"
        ],
        "snippet_pediatricDosingSection": [
            "pediatricDosageSection"
        ],
        "snippet_raSection": [
            "fdaUsesSection",
            "raUsesSection"
        ],
        "snippet_nonfdaSection": [
            "nonFdaUsesSection"
        ],
        "snippet_dosageAdjustmentsSection": [
            "doseAdjustmentsSection"
        ],
        "administrationSection": [
            "administrationSection"
        ],
        "comparativeEfficacySection":[
          "comparativeEfficacySection"
        ],
        "snippet_contraindicationsSection": [
            "contraindications"
        ],
        "snippet_precautionsSection": [
            "precautions"
        ],
        "adverseEffectsSection": [
            "adverseReactionsSection"
        ],
        "blackBoxWarningSection": [
            "blackboxwarning"
        ],
        "snippet_remsSection": [
            "rems"
        ],
        "interactionsSection": [
            "druginteractions"
        ],
        "ivCompatibilitySection": [
            "IVcompatibility"
        ],
        "snippet_pregnancyCategorySection": [
            "pregnancyandlactation"
        ],
        "snippet_breastFeedingRatingSection": [
            "pregnancyandlactation"
        ],
        "monitoringSection": [
            "monitoring"
        ],
        "snippet_doNotConfuseSection": [
            "donotconfuse"
        ],
        "mechanismOfActionSection": [
            "mechanismofaction"
        ],
        "pharmacokineticsSection": [
            "pharmacokinetics"
        ],
        "snippet_clinicalTeachingSection": [
            "medicationconsulting",
            "patienthandouts"
        ],
        "snippet_clinicalEffectsSection": [
            "clinicaleffects"
        ],
        "snippet_rangeOfToxicitySection": [
            "rangeoftoxicity"
        ],
        "snippet_treatmentSection": [
            "treatment"
        ],
        "snippet_howSuppliedSection": [
            "howsupplied"
        ],
        "snippet_drugPropertiesSection": [
            "drugproperties"
        ],
        "snippet_storageandstabilitySection": [
            "storageandstability"
        ],
        "snippet_tradenamesSection": [
            "tradenames"
        ],
        "snippet_regulatoryStatusSection": [
            "regulatorystatus"
        ],
        "referencesSection": [
            "references"
        ]
    }
}

export {
    in_depth_reference_data,
    TEMPLATE_TYPE_IN_DEPTH,
    TEMPLATE_TYPE_REFERENCES,
    TEMPLATE_TYPE_WAITING,
    TEMPLATE_TYPE_IV_COMPATIBILITY,
    TEMPLATE_TYPE_LINK_TO_QUICK_ANSWERS
}
