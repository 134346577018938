/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
export const NO_SUBSCRIPTION_HEADER = "Micromedex Subscription Plans";
export const NO_SUBSCRIPTION_MESSAGE = "The current subscription does not include this content. Contact Support to learn more about subscription options.";
export const NO_SUBSCRIPTION_MESSAGE_POPUP = "A subscription upgrade is needed to access this content. To ﬁnd out more about subscription options, contact us for product support details on Micromedex.";

/*For support page: Drooping IBM word and not replacing it with Merative: 12-19-2022*/
export const NO_SUBSCRIPTION_BUTTON_TEXT = "Support";
export const NO_SUBSCRIPTION_LINK = "https://www.merative.com/clinical-decision-support";
export const NO_RESULTS_AVAILABLE = 'No results available';
export const SECURED_ORIGIN_FROM_CLIEN_CONFIG = 'gateway_login_page.mdxAuthenticationJson.Client_Config.secured_origins'
export const STATE_PATH_TO_GATEWAY_AUTHORIZATION = 'gateway_login_page.mdxAuthenticationJson..authorization'
export const AUTHORIZATION_SUCCESS = 'success'
export const RELATED_RESULTS_ERROR_TYPE = 'relatedResults'
export const DRUG_INTERACTION_ERROR_TYPE = 'drugInteraction'
export const IV_COMPATIBILITY_ERROR_TYPE = 'ivCompatibility'
export const DIRECT_SEARCH_ERROR_TYPE = 'directSearch'
export const CHATBOT_ERROR_TYPE = 'chatbot'
export const OTHER_ERROR_TYPE = 'other'
export const GENERIC_ERROR_HEADER = `An unexpected error has occurred`
export const GENERIC_ERROR_MESSAGE = `An unexpected error has occurred. If this problem persists, try again later`
export const IP_AUTH_ERROR_HEADER = `Login access failed`
export const IP_AUTH_ERROR_MESSAGE = `Unable to access Micromedex content because the current website did not correctly provide login information. Try again. If the problem persists, report this error to the website’s help desk.`
export const IP_AUTH_ERROR_CAPTION = `[IP Authentication error]`
export const TOKEN_AUTH_ERROR_HEADER = IP_AUTH_ERROR_HEADER
export const TOKEN_AUTH_ERROR_MESSAGE = IP_AUTH_ERROR_MESSAGE
export const TOKEN_AUTH_ERROR_CAPTION = `[Token Authentication error]`
export const UNEXPECTED_ERROR_MESSAGE = `An unexpected error occurred; if the problem persists, try again later.`
export const LOGIN_PAGE_PATH = '/login'
export const ERROR_PAGE_PATH = '/errorpage'
export const MAIN_PAGE_PATH = '/'
export const SUBSCRIPTION_UPGRADE_MESSAGE = 'A subscription upgrade is needed to access this content. To find out more about subscription options, contact Support product support details on Micromedex.'
export const NO_SUBSCRIPTION_HEADING = 'Content not accessible with current subscription'
export const ERROR_MODAL_REFRESH_MESSAGE = 'Try refreshing this page, or go back the previous page. If the problem persists, try again later.'
export const ERROR_MODAL_REFRESH_MESSAGE_LOGIN = 'Try refreshing this page. If the problem persists, try again later.'
export const ERROR_MODAL_SESSION_EXPIRED_HEADER = 'Session expired'
export const ERROR_MODAL_SESSION_EXPIRED_MESSAGE = 'The session has timed out due to inactivity. Log in again.'
export const ERROR_MODAL_SESSION_EXPIRED_IFRAME_TOKEN_MESSAGE = 'The session has timed out due to inactivity. Refresh this page to begin again.'
export const ERROR_MODAL_SESSION_EXPIRED_IFRAME_IP_MESSAGE = 'The session has timed out due to inactivity. If the problem persists, report this error to the website\'s help desk.'
export const ERROR_MODAL_SERVICE_UNAVAILABLE_HEADER = 'Content unavailable'
export const ERROR_MODAL_SERVICE_UNAVAILABLE_MESSAGE = 'Unable to access Micromedex content because it is temporarily down for maintenance, or due to high volume. Close this message and try again. If the problem persists, report this error to the website’s help desk.'
export const INCORRECT_LOGIN_CREDENTIALS_MESSAGE = 'Incorrect username or password'
export const INCORRECT_LOGIN_CREDENTIALS_HEADER = 'Try again.'
export const ERROR_MODAL_TRY_AGAIN_BUTTON_TEXT = 'Try again'
export const ERROR_MODAL_CLOSE_BUTTON_TEXT = 'Close'

export const DID_YOU_MEAN_SEARCH_FOR_MESSAGE_1 = "Your search for: ";
export const DID_YOU_MEAN_SEARCH_FOR_MESSAGE_2 = " did not match any documents.";
export const DID_YOU_MEAN_SEARCH_INSTEAD_FOR_MESSAGE = "Did you mean: ";
export const REPLACE_SPACES_REGEX = /^\s+/;

//new Micromedex titles
export const TITLE_LOGIN_PAGE = "Micromedex Products: Please Login";
export const TITLE_HOME_PAGE = "Home - MICROMEDEX";
export const TITLE_DRUG_INTERACTION_SEARCH_PAGE = "Drug Interactions search - MICROMEDEX";
export const TITLE_DRUG_INTERACTION_RESULT_SUMMARY_PAGE = "Drug Interaction results - MICROMEDEX";
export const TITLE_DRUG_INTERACTION_DETAIL_PAGE = "Drug Interaction detail - MICROMEDEX";
export const TITLE_MONOGRAPH_QUICK_ANSWERS = "Drug Result - Quick Answers - MICROMEDEX";
export const TITLE_MONOGRAPH_IN_DEPTH_ANSWERS = "Drug Result - In-Depth Answers - MICROMEDEX";
export const TITLE_DRUG_CONSULT_PAGE = 'Drug Consults - MICROMEDEX';
export const TITLE_MICROMEDEX_ASSISTANT = "Micromedex Assistant - MICROMEDEX";

//IVCompatibility Titles
export const TITLE_IV_COMPATIBILITY_SEARCH_PAGE = "IV Compatibility search - MICROMEDEX";
export const TITLE_IV_COMPATIBILITY_RESULT_SUMMARY_PAGE = "IV Compatibility results - MICROMEDEX";
export const TITLE_IV_COMPATIBILITY_RESULT_DETAILS_PAGE = "IV Compatibility results detail - MICROMEDEX";
export const TITLE_IV_COMPATIBILITY_PRODUCT_DOCUMENT_PAGE = "IV Compatibility - Product Information";
