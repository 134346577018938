/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */

const TEMPLATE_TYPE_WAITING = "waiting"                                     //  0
const TEMPLATE_TYPE_TWO_LEVEL = "two_level_list"                            //  1
const TEMPLATE_TYPE_SINGLE_LEVEL_MODAL = "single_level_list_modal_popups"   //  2
const TEMPLATE_TYPE_SINGLE_LEVEL = "single_level_list"                      //  3
const TEMPLATE_TYPE_NO_QUICK = "no_quick_answers"                           //  4
const TEMPLATE_TYPE_TWO_LEVEL_BOLDED ="two_level_list_bolded_li"            //  5
const TEMPLATE_TYPE_DRUG_INTERACTIONS = "drug_interactions"                 //  6
const TEMPLATE_TYPE_IV_COMPATIBILITY = "iv_compatibility"                   //  7
const TEMPLATE_TYPE_CARENOTES = "carenotes_links"                           //  8
const TEMPLATE_TYPE_TRADE_NAMES = "trade_names_links"                       //  9
const TEMPLATE_TYPE_REFERENCES = "references_format"                        // 10
const TEMPLATE_TYPE_NAMED_SECTION_LIST = "named_section_list";              // 11
const TEMPLATE_TYPE_PREGNANCY_AND_LACTATION = "pregnancy_and_lactation"     // 12

const  drug_points_reference_data = {

    "content_set_id": "100",
    "quick":"100",
    "in-depth":"31", // TODO: figure out content set id for in-depth
    "all_quick_answers_topics" :
                                {
                                "adultDosingSection":"snippet_adultDosingSection",
                                "pediatricDosingSection":"snippet_pediatricDosingSection",
                                "raSection":"snippet_raSection",
                                "nonfdaSection":"snippet_nonfdaSection",
                                "dosageAdjustmentsSection":"snippet_dosageAdjustmentsSection",
                                "administrationSection":"administrationSection",


                                "contraindicationsSection":"snippet_contraindicationsSection",
                                "precautionsSection":"snippet_precautionsSection",
                                "adverseEffectsSection":"adverseEffectsSection",
                                "blackBoxWarningSection":"blackBoxWarningSection",
                                "remsSection":"snippet_remsSection",

                                // DE76336: mdx-proxy now splits this into the next two sections: "pregnancyLactationSection"
                                "pregnancyCategorySection":"pregnancyCategorySection",
                                "breastFeedingRatingSection":"breastFeedingRatingSection",

                                "monitoringSection":"monitoringSection",
                                "doNotConfuseSection":"snippet_doNotConfuseSection",

                                "mechanismOfActionSection":"mechanismOfActionSection",
                                "pharmacokineticsSection":"pharmacokineticsSection",
                                "clinicalTeachingSection":"snippet_clinicalTeachingSection",


                                "clinicalEffectsSection":"snippet_clinicalEffectsSection",
                                "rangeOfToxicitySection":"snippet_rangeOfToxicitySection",
                                "treatmentSection":"snippet_treatmentSection",

                                "howSuppliedSection":"snippet_howSuppliedSection",
                                "tradenamesSection":"snippet_tradenamesSection",
                                "regulatoryStatusSection":"snippet_regulatoryStatusSection",
                                "referenceSection":"referenceSection",
                                "staticInfo":"staticInfo"
                                },
    "adultDosingSection": {
        "template_type": "two_level_list",
        "main_topic": "snippet_adultDosingSection",
        "group_title": "Dosing/Administration",
        "section_title": "Adult Dosing",
        "topic_ids": [
            "snippet_adultDosingSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "pediatricDosageSection": {
        "template_type": "two_level_list",
        "main_topic": "snippet_pediatricDosingSection",
        "group_title": "Dosing/Administration",
        "section_title": "Pediatric Dosing",
        "topic_ids": [
            "snippet_pediatricDosingSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "fdaUsesSection": {
        is_fda_use_section: "true",
        "template_type": "single_level_list_modal_popups",
        "main_topic": "snippet_raSection",
        "group_title": "Dosing/Administration",
        "section_title": "FDA Uses",
        "topic_ids": [
            "snippet_raSection",
            "staticInfo",
            "detailsInDrugdexSection"
        ]
    },
    "raUsesSection": {
        is_fda_use_section: "true",
        "template_type": "single_level_list_modal_popups",
        "main_topic": "snippet_raSection",
        "group_title": "Dosing/Administration",
        "section_title":"Labeled Uses",
        "topic_ids": [
            "snippet_raSection",
            "staticInfo",
            "detailsInDrugdexSection"
        ]
    },
    "nonFdaUsesSection": {
        is_fda_use_section: "true",
        "template_type": "single_level_list_modal_popups",
        "main_topic": "snippet_nonfdaSection",
        "group_title": "Dosing/Administration",
        "section_title":"Non-FDA Uses",
        "topic_ids": [
            "snippet_nonfdaSection",
            "staticInfo",
            "detailsInDrugdexSection"
        ]
    },
    "doseAdjustmentsSection": {
        "template_type": "single_level_list",
        "main_topic": "snippet_dosageAdjustmentsSection",
        "group_title": "Dosing/Administration",
        "section_title": "Dose Adjustments",
        "topic_ids": [
            "snippet_dosageAdjustmentsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "administrationSection": {
        "template_type": "two_level_list",
        "main_topic": "administrationSection",
        "group_title": "Dosing/Administration",
        "section_title": "Administration",
        "topic_ids": [
            "administrationSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    // TODO: fill in topic_ids, no topics mapped in micromedix, it is "" <<<<<<<<<<<<<<<
    //We need section name to return no results with key...
    "comparativeEfficacySection": {
        "template_type": "no_quick_answers",
        "group_title": "Dosing/Administration",
        "section_title": "Comparative Efficacy",
        "topic_ids": ["detailsInDrugdexSection"]
    },
    // TODO: fill in topic_ids, no topics mapped in micromedix, it is "" <<<<<<<<<<<<<<<
    //We need section name to return no results with key...
    "placeInTherapySection": {
        "template_type": "no_quick_answers",
        "group_title": "Dosing/Administration",
        "section_title": "Place In Therapy",
        "topic_ids": ["detailsInDrugdexSection"]
    },
    "contraindications": {
        "template_type": "single_level_list",
        "main_topic": "snippet_contraindicationsSection",
        "group_title":"Medication Safety",
        "section_title": "Contraindications",
        "topic_ids": [
            "snippet_contraindicationsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "precautions": {
        "template_type": "single_level_list",
        "main_topic": "snippet_precautionsSection",
        "group_title":"Medication Safety",
        "section_title": "Precautions",
        "topic_ids": [
            "snippet_precautionsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "adverseReactionsSection": {
        "template_type": "two_level_list_bolded_li",
        "group_title":"Medication Safety",
        "section_title": "Adverse Effects",
        "main_topic": "adverseEffectsSection",
        "topic_ids": [
            "adverseEffectsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "blackboxwarning": {
        "template_type": "two_level_list",
        "main_topic": "blackBoxWarningSection",
        "group_title":"Medication Safety",
        "section_title": "Boxed Warning",
        "topic_ids": [
            "blackBoxWarningSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "rems": {
        "template_type": "two_level_list",
        "main_topic": "snippet_remsSection",
        "group_title":"Medication Safety",
        "section_title": "REMS",
        "topic_ids": [
            "snippet_remsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    // TODO: review output <<<<<<<<<<<<<<
    "druginteractions": {
        "template_type": "drug_interactions",
        "group_title":"Medication Safety",
        "main_topic":"interactionsSection",
        "section_title": "Drug Interactions (single)",
        "topic_ids": [
            "interactionsSection"
        ]
    },
    // TODO: nothing shows up <<<<<<<<<<<<<<<<<<<<<<<<<<<<
    "IVcompatibility": {
        "template_type": "iv_compatibility",
        "group_title":"Medication Safety",
        "main_topic":"ivCompatibilitySection",
        "section_title": "IV Compatibility (single)",
        "topic_ids": [
            "ivCompatibilitySection"
        ]
    },
    "pregnancyandlactation": {
        "template_type": TEMPLATE_TYPE_PREGNANCY_AND_LACTATION,
        "group_title":"Medication Safety",
        "section_title": "Pregnancy & Lactation",
        "main_topic":["pregnancyCategorySection","breastFeedingRatingSection"],
        "topic_ids": [
            "pregnancyCategorySection",
            "breastFeedingRatingSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "monitoring": {
        "template_type": "single_level_list",
        "main_topic": "monitoringSection",
        "group_title":"Medication Safety",
        "section_title": "Monitoring",
        "topic_ids": [
            "monitoringSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "donotconfuse": {
        "template_type": "single_level_list",
        "main_topic": "snippet_doNotConfuseSection",
        "group_title":"Medication Safety",
        "section_title": "Do Not Confuse",
        "topic_ids": [
            "snippet_doNotConfuseSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "mechanismofaction": {
        "template_type": "single_level_list",
        "main_topic": "mechanismOfActionSection",
        "group_title": "Mechanism of Action",
        "section_title": "Mechanism of Action",
        "topic_ids": [
            "mechanismOfActionSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "pharmacokinetics": {
        "template_type": "named_section_list",
        "group_title":"Pharmacokinetics",
        "main_topic":"pharmacokineticsSection",
        "section_title":"Pharmacokinetics",
        "topic_ids": [
            "pharmacokineticsSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "medicationconsulting": {
        "template_type": "single_level_list",
        "main_topic": "snippet_clinicalTeachingSection",
        "group_title": "Patient Education",
        "section_title": "Medication Counseling",
        "topic_ids": [
            "snippet_clinicalTeachingSection", // ???????????????????
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "patienthandouts": {
        "template_type": "carenotes_links",
        "group_title":"Patient Education",
        "section_title": "Patient Handouts",
        "main_topic":"snippet_clinicalTeachingSection",
        "topic_ids": [
            "snippet_clinicalTeachingSection", // ????????????
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "clinicaleffects": {
        "template_type": "two_level_list",
        "group_title": "Toxicology",
        "main_topic":"snippet_clinicalEffectsSection",
        "section_title": "Clinical Effects",
        "topic_ids": [
            "snippet_clinicalEffectsSection",
            "detailsInDrugdexSection"
        ]
    },
    "rangeoftoxicity": {
        "template_type": "two_level_list",
        "group_title": "Toxicology",
        "main_topic":"snippet_rangeOfToxicitySection",
        "section_title": "Range of Toxicity",
        "topic_ids": [
            "snippet_rangeOfToxicitySection",
            "detailsInDrugdexSection"
        ]
    },
    "treatment": {
        "template_type": "two_level_list",
        "group_title": "Toxicology",
        "section_title": "Treatment",
        "main_topic":"snippet_treatmentSection",
        "topic_ids": [
            "snippet_treatmentSection",
            "detailsInDrugdexSection"
        ]
    },
    "howsupplied": {
        "template_type": "two_level_list",
        "group_title":"About",
        "main_topic":"snippet_howSuppliedSection",
        "section_title": "How Supplied",
        "topic_ids": [
            "snippet_howSuppliedSection",
            "detailsInDrugdexSection"
        ]
    },
    // TODO: fill in topic_ids, no topics mapped in micromedix, it is "" <<<<<<<<<<<<<<<
    //We need section name to return no results with key...
    "drugproperties": {
        "template_type": "no_quick_answers",
        "group_title":"About",
        "section_title": "Drug Properties",
        "topic_ids": ["detailsInDrugdexSection"]
    },
    // TODO: fill in topic_ids, no topics mapped in micromedix, it is "" <<<<<<<<<<<<<<<
    //We need section name to return no results with key...
    "storageandstability": {
        "template_type": "no_quick_answers",
        "group_title":"About",
        "section_title": "Storage & Stability",
        "topic_ids": ["detailsInDrugdexSection"]
    },
    "tradenames": {
        "template_type": "trade_names_links",
        "group_title":"About",
        "main_topic":"snippet_tradenamesSection",
        "section_title": "Trade Names",
        "topic_ids": [
            "snippet_tradenamesSection",
            "detailsInDrugdexSection"
        ]
    },
    "regulatorystatus": {
        "template_type": "single_level_list",
        "main_topic": "snippet_regulatoryStatusSection",
        "group_title": "About",
        "section_title": "Regulatory Status",
        "topic_ids": [
            "snippet_regulatoryStatusSection",
            "staticInfo",
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    },
    "references": {
        "template_type": "references_format",
        "main_topic": "referenceSection",
        "group_title": "About",
        "section_title": "References",
        "topic_ids": [
            "referenceSection",
            "detailsInDrugdexSection"
        ]
    }
}

export {
    drug_points_reference_data,
    TEMPLATE_TYPE_TWO_LEVEL,
    TEMPLATE_TYPE_SINGLE_LEVEL_MODAL,
    TEMPLATE_TYPE_SINGLE_LEVEL,
    TEMPLATE_TYPE_NO_QUICK,
    TEMPLATE_TYPE_TWO_LEVEL_BOLDED,
    TEMPLATE_TYPE_DRUG_INTERACTIONS,
    TEMPLATE_TYPE_IV_COMPATIBILITY,
    TEMPLATE_TYPE_CARENOTES,
    TEMPLATE_TYPE_TRADE_NAMES,
    TEMPLATE_TYPE_REFERENCES,
    TEMPLATE_TYPE_WAITING,
    TEMPLATE_TYPE_NAMED_SECTION_LIST,
    TEMPLATE_TYPE_PREGNANCY_AND_LACTATION
}
